import React from 'react';
import BounceLoader from "react-spinners/BounceLoader";

function AppLoader() {
    return ( 
    <div className="lazy-loading">
        <BounceLoader color="#7a2396" size={50} />
    </div>       
    
    )
}

export default AppLoader;
