import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppLoader from "./components/AppLoader";
import RouteWithSubRoutes from "./RouteWithSubRoutes";
const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/Home"));
const Register = lazy(() => import('./pages/Register'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const MyAccount = lazy(() => import('./pages/MyAccount'));
const MyPurchases = lazy(() => import('./pages/MyPurchases'));
const Purchases = lazy(() => import('./pages/Purchases'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const About = lazy(() => import('./pages/About'));
const LatestStories = lazy(() => import('./pages/LatestStories'));
const Favourites = lazy(() => import('./pages/Favourites'));
const MyBag = lazy(() => import('./pages/MyBag'));
const Listing = lazy(() => import('./pages/Listing'));
const Product = lazy(() => import('./pages/Product'));
const PurchasedProduct = lazy(() => import('./pages/PurchasedProduct'));
const SearchList = lazy(() => import('./pages/SearchList'));
const SendFeedback = lazy(() => import('./pages/SendFeedback'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/my-account',
    component: MyAccount,
  },
  {
    path: '/my-purchases',
    component: MyPurchases,
  },
  {
    path: '/purchases',
    component: Purchases,
  },
  {
    path: '/change-password',
    component: ChangePassword,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/latest-stories',
    component: LatestStories,
  },
  {
    path: '/favourites',
    component: Favourites,
  },
  {
    path: '/my-bag',
    component: MyBag,
  },
  {
    path: '/list',
    component: Listing,
  },
  {
    path: '/product',
    component: Product,
  },
  {
    path: '/purchased-product',
    component: PurchasedProduct,
  },
  {
    path: '/search-results',
    component: SearchList,
  },
  {
    path: '/send-feedback',
    component: SendFeedback,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
];

const AppRouter = () => {
  return (
    <Router>
        <Suspense fallback={<AppLoader />}>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Suspense>
    </Router>
  );
};

export default AppRouter;
